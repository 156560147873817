/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiSendEmail",
            "endpoint": "https://9dv0l7q72c.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "nokioweb-20240324164737-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3o8y6kemypyg3.cloudfront.net"
};


export default awsmobile;
